
import { ComponentPublicInstance, computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import UITable from '@/components/UI/UITable.vue';
import UITextInput from '@/components/UI/UITextInput.vue';
import UITextDropdown from '@/components/UI/UITextSelect.vue';
import UIModal from '@/components/UI/UIModal.vue';
import UIButton from '@/components/UI/UIButton.vue';
import { cleanSource, rutCleaner, rutFormatter, validateForm } from '@/utils';
import { canchasState } from '@/store/mantenedores.store';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { showToast } from '@/hooks/useToast';
import { SocketIO } from '@/utils/socketUtils';
import { userState } from '@/store/user.store';

export default defineComponent({
  name: 'MiiImporter',
  components: {
    'ui-table': UITable,
    'ui-text-input': UITextInput,
    'ui-text-select': UITextDropdown,
    'ui-modal': UIModal,
    'ui-button': UIButton
  },
  setup() {
    interface IUserResponse {
      cancha: string;
      createdAt: number;
      email: string;
      nombre: string;
      role: string;
      rut: string;
      updatedAt: number;
    }
    const nameRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const passwordRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const emailRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const rutRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const canchaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const rolRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);

    const modalState = ref(false);
    const modalStatus = ref<'EDIT' | 'CREATE' | 'PASSWORD'>('EDIT');
    watch(() => modalState.value, val => {
      if(val === false) {
        closeModal();
      }
    });

    const formData = reactive<{[k: string] : string | undefined}>({
      nombre: '',
      email: '',
      rut: '',
      password: '',
      role: '',
      cancha: '',
    });
    const editPasswordModal = (i: number) => {
      const user = users.value[i];
      userId.value = user.uid;
      modalState.value = true;
      modalStatus.value = 'PASSWORD';
    };

    const editing = ref(false);
    const editUserModal = (i: number) => {
      editing.value = true;
      const user = users.value[i];
      userId.value = user.uid;
      formData.nombre = user.data.nombre;
      formData.email = user.data.email;
      formData.rut = rutCleaner(rutFormatter(user.data.rut));
      formData.role = user.data.role;
      rolDefault.value =  user.data.role;
      user.data.cancha !== 'ALL' && (canchaDefault.value = user.data.cancha);
      modalState.value = true;
      modalStatus.value = 'EDIT';
      editing.value = false;
    };
    const createUserModal = () => {
      modalState.value = true;
      modalStatus.value = 'CREATE';
    };
    const closeModal = () => {
      modalState.value = false;
      formData.nombre = '';
      formData.email = '';
      formData.rut = '';
      formData.password = '';
      formData.role = '';
      formData.cancha = '';
      userId.value = '';
      rolRef.value?.cleanValue();
      canchaRef.value?.cleanValue();
    };

    const headers = [
      { label: 'Nombre', key: 'nombre', format: '' },
      { label: 'Rut', key: 'rut', format: 'dni' },
      { label: 'Email', key: 'email', format: '' },
      { label: 'Rol', key: 'role', format: '' },
      { label: 'Estado', key: 'active', format: '' },
      { label: 'Cancha', key: 'canchaName', format: '' }
    ];
    const actions = ref([ 
      { label: 'EDITAR', class: 'bg-yellow-600 text-white', cb: (i: number) => editUserModal(i) },
      { label: 'ESTADO', class: 'bg-yellow-600 text-white', cb: (i: number) => changeUserStatus(i) }
    ]);

    const roles = computed(() => {
      if (userState.value?.role === 'ADMINISTRADOR') {
        return [
          { id: 'ADMINISTRADOR', label: 'ADMINISTRADOR' },
          { id: 'SUPERVISOR', label: 'SUPERVISOR' },
          { id: 'RECEPTOR', label: 'RECEPTOR' },
          { id: 'CONSULTOR', label: 'CONSULTOR' },
          { id: 'ASESOR', label: 'ASESOR' }
        ];
      } else if (userState.value?.role === 'SUPERVISOR') {
        return [ { id: 'RECEPTOR', label: 'RECEPTOR' } ];
      } else {
        return [];
      }
    });
    
    const allCanchas = computed(() => canchasState.value);
    const canchas = computed(() => {
      const c = [...cleanSource(allCanchas.value.map(el => ({ id: el._id, label: `${el.codigo} ${el.descripcion}` })))];
      // c.pop();
      // c.pop();
      const r = ['ADMINISTRADOR', 'SUPERVISOR', 'CONSULTOR'];
      if (formData.role === 'RECEPTOR' || formData.role === 'ASESOR') return [...c];
      else if (r.includes(formData.role+'')) return [ { id: 'ALL', label: 'Todas las Canchas' } ];
      return [];
    });
    const rolDefault = ref('');
    const canchaDefault = ref<any>(null);
    watch(() => formData.role, () => {
      const r = ['ADMINISTRADOR', 'SUPERVISOR', 'CONSULTOR'];
      if (formData.role === 'RECEPTOR') {
        //
        // if (!editing.value) {
        //   formData.cancha = '';
        //   canchaDefault.value = '';
        //   canchaRef.value?.cleanValue();
        // }
        //
      } else if (r.includes(formData.role+'')) {
        formData.cancha = 'ALL';
        canchaDefault.value = 'ALL';
      } else {
        formData.cancha = '';
        canchaDefault.value = '';
        canchaRef.value?.cleanValue();
      }
    });

    const isValid = (): boolean => {
      return validateForm([nameRef, emailRef, rutRef, canchaRef, rolRef, passwordRef]);
    };
    const isLoading = ref(false);

    const createUser = async () => {
      try {
        isLoading.value = true;
        if (isValid()) {
          const request = await makeRequest(METHODS.POST, 'auth/create', true, cleanSource(formData));
          if (request.ok) {
            showToast('SUCCESS', 'Usuario creado');
            modalState.value = false;
          } else {
            showToast('ERROR', request.errors+'');
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const changePassword = async() => {
      try {
        isLoading.value = true;
        if (validateForm([passwordRef])) {
          const body = { password: formData.password };
          const request = await makeRequest(METHODS.PATCH, `auth/password/${userId.value}`, true, body);
          if (request.ok) {
            showToast('SUCCESS', 'Contraseña actualizada');
            modalState.value = false;
          } else {
            showToast('ERROR', request.errors+'');
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const editUser = async () => {
      try {
        isLoading.value = true;
        if (validateForm([nameRef, emailRef, rutRef, canchaRef, rolRef])) {
          const body = cleanSource(formData);
          delete body.password;
          const request = await makeRequest<any>(METHODS.PATCH, `auth/user/${userId.value}`, true, body);
          if (request.ok) {
            showToast('SUCCESS', 'Usuario editado');
            modalState.value = false;
          } else {
            showToast('ERROR', request.errors+'');
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const changeUserStatus = async (i: number) => {
      try {
        const user: any = users.value[i];
        const request = await makeRequest<any>(METHODS.PATCH, `auth/user-status/${user.uid}`, true);
        if (request.ok) {
          showToast('SUCCESS', 'Estado de usuario actualizado');
          // users.value[i].data.active = request.payload?.active ? 'ACTIVO' : 'INACTIVO';
        }
        else showToast('ERROR', request.errors+'');
      } catch (error) {
        console.log(error);
      }
    };

    const userId = ref('');
    const users = ref<any>([]);
    const socket = SocketIO.getInstance();
    const updateUserCb = (user: any) => {
      console.log({ user });
      const i = users.value.findIndex((el:any) => el.uid === user._id);
      if (i !== -1) {
        users.value[i].data.nombre = user.nombre;
        users.value[i].data.rut = user.rut;
        users.value[i].data.email = user.email;
        users.value[i].data.role = user.role;
        users.value[i].data.estado = user.active ? 'ACTIVO':'INACTIVO';
        users.value[i].data.cancha = user.cancha;
        users.value[i].data.canchaName = users.value[i].data.cancha === 'ALL' ? 'TODAS' : allCanchas.value.find(c => c._id === users.value[i].data.cancha)?.descripcion.toUpperCase();

      }
    };
    const updateUserStateCb = (user: any) => {
      const i = users.value.findIndex((el:any) => el.uid === user._id);
      i !== -1 && (users.value[i].data.active = user.active ? 'ACTIVO' : 'INACTIVO');
    };
    const addUserCb = (el: any) => {
      const user = {
        uid: el._id,
            data: {
              _id: el._id,
              nombre: el.nombre,
              email: el.email,
              role: el.role,
              rut: el.rut,
              active: el.active ? 'ACTIVO' : 'INACTIVO',
              cancha: el.cancha,
              canchaName: el.cancha === 'ALL' ? 'TODAS' : allCanchas.value.find(c => c._id === el.cancha)?.descripcion.toUpperCase()
            }
      };
      users.value.push(user);
    };
    onMounted(async() => {
      if (userState.value?.role === 'ADMINISTRADOR') actions.value.push({ label: 'CONTRASEÑA', class: 'bg-green-600 text-white', cb: (i: number) => editPasswordModal(i) });
      const roomEvents = [
        { evt: 'created-user', cb: addUserCb },
        { evt: 'updated-user', cb: updateUserCb },
        { evt: 'updated-user-state', cb: updateUserStateCb }
      ];
      socket.joinRoom('users-ALL', roomEvents);
      const request = await makeRequest<IUserResponse[]>(METHODS.GET, 'auth/users', true);
      if (request.ok) {
        users.value = request.payload?.map((el: any) => ({
            uid: el._id,
            data: {
              _id: el._id,
              nombre: el.nombre,
              email: el.email,
              role: el.role,
              rut: el.rut,
              active: el.active ? 'ACTIVO' : 'INACTIVO',
              cancha: el.cancha,
              canchaName: el.cancha === 'ALL' ? 'TODAS' : allCanchas.value.find(c => c._id === el.cancha)?.descripcion.toUpperCase()
            }
          }));
      }
    });
    onBeforeUnmount(async() => {
      await socket.leaveRoom('users-ALL');
    });
    

    return {
      headers,
      actions,
      modalState,
      editUserModal,
      modalStatus,
      createUserModal,
      roles,
      canchas,
      formData,
      nameRef,
      emailRef,
      rutRef,
      canchaRef,
      passwordRef,
      rolRef,
      canchaDefault,
      rolDefault,
      isLoading,
      editUser,
      createUser,
      users,
      closeModal,
      changePassword,
      userState
    };
  }
});
